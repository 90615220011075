<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  import moment from 'moment'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      data: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      xAxis:{
         type: Array,
         default: ()=>{
          return []
        }
      },
      units:{
        type:String,
        default: '曲线图'
      },
    },
    data() {
      return {
        chart:null
      }
    },
    watch:{
      'xAxis':{
        handler() {
           this.refreshData()
        },
        deep:true
      },
      'units':{
        handler() {
           this.refreshData()
        },
        deep:true,
      }
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
          this.chart.setOption({
              tooltip: {
                  trigger: 'axis',
                  textStyle:{
                    align:'left',
                  }
              },
              grid: {
                  top:'30px',
                  left: '20px',
                  right: '20px',
                  bottom: '5px',
                  containLabel: true
              },
              xAxis: {
                  boundaryGap: true,
                  type: 'category',
                  data: this.xAxis,
              },
              yAxis: {
                  type: 'value',
              },
              series: [
                {
                   name:this.units,
                   type:'line',
                   data:this.data,  
                }
              ]
          })
            
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>