<template>
    <div class="pageBox">
        <div class="mainPage">
            <div class="containTitle">
                <div class="title">评估内容</div>
                <div class="titleLine"></div>
            </div>
            <div class="containBox">
                <div>名称 : {{ data.name }}</div>
                <div>博物馆名称 : {{ data.position }}</div>
                <div>文物名称 : {{ data.relicname }}</div>
                <div>材质 : {{ data.material }}</div>
                <div>文物信息 : {{ data.introductory }}</div>
                <div class="chartBox">
                    <div>
                        <el-radio-group v-model="radio" size="large" @change="changeOk">
                            <el-radio-button v-for="(item, index) in senerS" :key="index" :label="item.id">{{ item.label }}</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="chart">
                        <chartline :data="chartValue" :xAxis="chartTime" :units="chartUnit"/>
                    </div>
                </div>
            </div>
            <div class="containTitle">
                <div class="title">评估结果</div>
                <div class="titleLine"></div>
            </div>
            <div class="containBox">
                <div>得分 : {{ score }}</div>
            </div>
            <div class="containTitle">
                <div class="title">相似案例</div>
                <div class="titleLine"></div>
            </div>
            <div class="containBox">
                <div v-for="(item, index) in resultDecision" :key="index">
                    <div class="itemCase">
                        <div class="titleCase">案例{{ index + 1 }}</div>
                        <div style="width: 100%;padding: 20px; box-sizing: border-box;">
                            <div>调控措施：{{ item.controlPlan }}</div>
                            <div>案例描述：{{ item.description }}</div>
                            <div>相似度：{{ Math.round(item.similarity) }}%</div>
                        </div>
                    </div>
                </div>
                <div v-if="resData">{{ resData }}</div>
            </div>
            <div class="containTitle">
                <div class="title">调控方案</div>
                <div class="titleLine"></div>
            </div>
            <div class="containBox">
                <div v-for="(item, index) in makeFun" :key="index">
                    <div class="itemCase">
                        <div class="titleCase">{{ item.name }}</div>
                        <div style="width: 100%;padding: 20px; box-sizing: border-box;">
                            <div v-for="(i, jindex) in item.data" :key="jindex">
                                {{jindex+1}}：{{ i }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="makeFun.length==0">
                    无
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { hisRiskDetils } from '@/api/openHome.js'
import chartline from './chart.vue'
export default{
    components:{
        chartline
    },
    data(){
        return{
            id:null,
            data:{},
            score:null,
            radio:null,
            senerSAll:[
                {label:'湿度',id:'01',unit:'%RH'},
                {label:'温度',id:'02',unit:'℃'},
                {label:'二氧化碳',id:'03',unit:'ppm'},
                {label:'光照度',id:'04',unit:'lx'},
                {label:'紫外辐射强度',id:'05',unit:'μw/cm2'},
                {label:'有机挥发物总量VOC（异丁烯）',id:'06',unit:'ppm'},
                {label:'有机污染物',id:'07',unit:'mg/m²'},
                {label:'无机污染物',id:'08',unit:'mg/m²'},
                {label:'含硫污染物',id:'09',unit:'mg/m²'},
            ],
            senerS:[],
            senerData:[],
            chartValue:[],
            chartTime:[],
            chartUnit:'曲线图',
            makeFun:[],
            resultDecision:[],
            resData:null
        }
    },
    mounted(){
        this.id = this.$route.query.riskid
        if(this.id){
            this.getDetils()
        }
    },
    methods:{
        getDetils(){
            this.makeFun = []
            this.resultDecision = []
            this.resData = null
            hisRiskDetils(this.id).then(r=>{
                this.data = r.data
                this.score = Math.round(JSON.parse(r.data.result).score) 
                this.senerData = JSON.parse(r.data.data)
                let a = Object.keys(this.senerData)
                this.senerS = [...this.senerSAll].filter(x => [...a].some(y => y === x.id))
                this.changeOk(this.senerS[0].id)

                let decision = JSON.parse(r.data.suggest)
                if(decision){
                    let data = decision.result.resultDecision
                    if(Array.isArray(data)){
                        this.resultDecision = data
                    }else{
                        this.resData = data
                        return
                    }

                    let result = decision.result
                    for(let key in result){
                        if(key!='resultDecision'){
                            this.makeFun.push({
                                name:key,
                                data:result[key]
                            })
                        }
                    }
                }
            })
        },
        changeOk(val){
            this.radio = val
            this.chartValue = this.senerData[this.radio].map(i=>{return i.envirParamValue})
            this.chartTime = this.senerData[this.radio].map(i=>{return i.collectTime})
            let sener =  this.senerS.find(v => v.id === val)
            this.chartUnit = `${sener.label}(${sener.unit})`
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width:100%;
    padding:0px;
    padding-top: 100px;
}

.mainPage{
    width: 1400px;
    min-height: 500px;
    margin:  0 auto;
    background-color: white;
    padding: 30px 20px;
    box-sizing: border-box;
    .containTitle{
        display: flex;
        justify-content: flex-start;
        .titleLine{
            height: 30px;
            width: calc(100% - 70px);
            box-sizing: border-box;
            border-bottom: 1px solid rgb(173, 173, 173);
        }
        .title{
            width: 70px;
            line-height: 30px;
            box-sizing: border-box;
            color: rgb(32, 72, 252);
            height: 30px;
            border-bottom: 2px solid rgb(32, 72, 252);
            font-weight: bold;
        }
    }
    .containBox{
        margin-top: 20px;
        line-height: 30px;
        .itemCase{
            width: 100%;
            border: 1px solid rgb(157, 157, 157);
            box-sizing: border-box;
            margin-bottom: 20px;
            .titleCase{
                line-height: 30px;
                font-size: 16px;
                text-align: center;
                border-bottom: 1px solid rgb(157, 157, 157);
                box-sizing: border-box;
                background-color: rgba($color: #1d94d3, $alpha: 1.0);
                color: white;
            }
        }
    }
}
.chartBox{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .chart{
        width: 100%;
        height: 400px;
    }
}
</style>